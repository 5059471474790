<script setup lang="ts">
import { useSiteStore } from '~/stores/site.store';
import { regions as reg } from '~/assets/data/config';
import Select from 'primevue/select';
import { globalKeys } from 'assets/data/global-keys';

const { $configService, $sitemapService, $storageService } = useServices();
const siteStore = useSiteStore();

const selectedCountry = computed(() =>
  reg.find((r) => r.code === siteStore.getRegion),
);

async function changeCountry(r: string) {
  $storageService.setUniversalStorage({
    key: globalKeys.developerConfigKeys.debugRegion,
    data: r,
  });
  document.location.href = '/';
}
</script>

<template>
  <Select
    class="bg-base rounded-lg w-full"
    :model-value="selectedCountry"
    :options="reg"
    option-label="name"
    option-value="code"
    placeholder="Select a Region"
    @change="(e) => changeCountry(e.value)"
  >
    <template #value="slotProps">
      <div v-if="slotProps.value" class="flex items-center">
        <div
          class="country-flag w-4 mr-2 rounded overflow-hidden"
          v-html="slotProps.value?.flag"
        />
        <div>{{ slotProps.value.name }}</div>
      </div>
      <span v-else>
        {{ slotProps.placeholder }}
      </span>
    </template>
    <template #option="slotProps">
      <div class="flex items-center">
        <div
          class="country-flag w-4 mr-2 rounded overflow-hidden"
          v-html="slotProps.option?.flag"
        />
        <div>{{ slotProps.option.name }}</div>
      </div>
    </template>
  </Select>
  <!-- </div> -->
</template>

<style scoped lang="scss"></style>
