<script setup lang="ts">
import { useLocaleStore } from '~/stores/locale.store';
import Select from 'primevue/select';
import ToggleButton from 'primevue/togglebutton';

const runtimeConfig = useRuntimeConfig();
const localeStore = useLocaleStore();
const locales = ref<string[]>();

onMounted(async () => {
  locales.value = await $fetch<string[]>(
    `${runtimeConfig.public.config}/cron/folders/locales/jackpotcity`,
  );
});

const availableLocales = computed(() =>
  locales.value?.map((l) => {
    return {
      code: l.split('.')[0],
      name: l.split('.')[0],
    };
  }),
);
</script>

<template>
  <div class="flex gap-2">
    <ToggleButton
      onLabel="Locale debug on"
      offLabel="Locale debug off"
      class="p-2 rounded-lg std-border text-sm truncate"
      :class="[
        {
          'bg-base text-base': !localeStore.debugEnabled,
          'bg-green-500 text-white font-bold': localeStore.debugEnabled,
        },
      ]"
      :model-value="localeStore.debugEnabled"
      @change="localeStore.toggleLocaleDebug()"
    />
    <Select
      :disabled="!availableLocales?.length"
      :model-value="localeStore.getLocale"
      option-label="name"
      option-value="code"
      :options="availableLocales"
      placeholder="Select Locale"
      class="w-full md:w-56 bg-base"
      size="small"
      @change="(e) => localeStore.changeLocale(e.value)"
    />
  </div>
</template>

<style scoped></style>
