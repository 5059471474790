<script lang="ts" setup>
import EnvironmentDebug from './environment-debug.vue';
import LocaleDebug from './locale-debug.vue';
import RegionDebug from './region-debug.vue';
import FeatureFlagDebug from './feature-flag-debug.vue';
import ModalDebug from './modal-debug.vue';
import Button from '../design-system/button.vue';
import { CogIcon, XMarkIcon } from '@heroicons/vue/24/outline';

const open = ref<boolean>(false);

const environment = useEnvironment();
const isDev = computed(
  () => import.meta.dev || environment.environment.localhost,
);

const tools = [
  {
    title: 'Locale',
    enabled: true,
    component: LocaleDebug,
  },
  {
    title: 'Region',
    enabled: isDev.value,
    component: RegionDebug,
  },
  {
    title: 'Environment',
    enabled: isDev.value,
    component: EnvironmentDebug,
  },
  {
    title: 'Feature Flags',
    enabled: true,
    component: FeatureFlagDebug,
  },
  {
    title: 'Modals',
    enabled: true,
    component: ModalDebug,
  },
];
</script>
<template>
  <div>
    <div
      class="fixed bottom-4 right-0 p-2 bg-primary-layer-alternative flex gap-2 justify-between std-border"
      :class="[
        { 'w-[calc(100vw-16px)] mx-2 rounded-lg': open, 'rounded-l-lg': !open },
      ]"
      :style="{ zIndex: 100 }"
    >
      <div
        class="flex items-center justify-between absolute -top-10 left-0"
        :class="{
          'w-full': open,
        }"
      >
        <div
          class="text-sm font-bold text-center bg-primary-blue-500 text-white p-2 capitalize"
          :class="{
            'w-full rounded-l': !open,
            rounded: open,
          }"
        >
          {{ environment.regionConigs.runtimeEnvironment }}
        </div>
        <Button v-if="open" type="neutral" padding="sm" @click="open = !open">
          <XMarkIcon :key="`${open}-xmark`" />
        </Button>
      </div>

      <Transition name="fade">
        <div
          v-if="open"
          class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-2"
        >
          <div
            v-for="(tool, index) in tools.filter(({ enabled }) => enabled)"
            :key="index"
            class="pr-2 border-r px-2"
          >
            <div class="text-xs text-base">{{ tool.title }}:</div>
            <component :is="tool.component" :key="tool.title" />
          </div>
        </div>
        <div v-else class="text-sm font-bold text-center">
          <div>Debug tools</div>
          <div>
            {{ isDev ? 'Local' : 'Deployed' }}
          </div>
          <Button
            type="tertiary-alternative"
            class="mx-auto"
            @click="open = !open"
          >
            <CogIcon
              v-if="!open"
              :key="`${open}-cog`"
              class="hover:animate-spin"
            />
            <XMarkIcon v-else :key="`${open}-xmark`" />
          </Button>
        </div>
      </Transition>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
