<script setup lang="ts">
import Button from '../design-system/button.vue';
import Drawer from 'primevue/drawer';
import ToggleSwitch from 'primevue/toggleswitch';

interface IFeatureFlags {
  features?: IFeatures[];
  description?: string;
  key?: string;
  name?: string;
}

interface IFeatures {
  description?: string;
  enabled?: boolean;
  id?: number;
  key?: string;
  name?: string;
}

const active = ref<boolean>(false);
const siteStore = useSiteStore();

const originalFeatureFlags = ref();
const featureFlags = ref<IFeatureFlags[]>(siteStore.getConfig.featureFlags);
const searchText = ref<string>();
const catToggle = ref();

function sortFeatureFlags(ff: IFeatureFlags[]) {
  const sortedFF: IFeatureFlags[] = ff;
  sortedFF.forEach((featureGroup) => {
    featureGroup.features.sort((a, b) => a.key.localeCompare(b.key));
  });

  featureFlags.value = sortedFF;
}

function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text);
}

function searchFF() {
  featureFlags.value = siteStore.getConfig.featureFlags.map((ff) => {
    const filteredFeat = ff.features.filter((feat) =>
      feat.key.toLowerCase().includes(searchText.value.toLowerCase()),
    );
    return { ...ff, features: filteredFeat };
  });
}

function setFFCategory(cat: string, index: number) {
  catToggle.value[cat] = !catToggle.value[cat];
  featureFlags.value[index].features.forEach((feat) => {
    siteStore.featureFlags.set(
      `${cat}.${feat.key}`.toLowerCase(),
      catToggle.value[cat],
    );
  });
}

function reset() {
  searchText.value = '';
  const newConfig = {
    ...siteStore.getConfig,
    featureFlags: originalFeatureFlags.value,
  };
  siteStore.setConfig(newConfig);
  featureFlags.value = siteStore.getConfig.featureFlags;
  Object.keys(catToggle.value).forEach((cat) => (catToggle.value[cat] = false));
}

onMounted(() => {
  originalFeatureFlags.value = [...siteStore.getConfig.featureFlags];
  siteStore.getConfig.featureFlags.forEach((ff) => {
    catToggle.value = { ...catToggle.value, [ff.key]: false };
  });
});

const debounceSearch = debounce(() => {
  searchFF();
}, 350);

function debounce(func, timeout) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
</script>
<template>
  <div class="flex gap-2">
    <Button
      class="font-normal std-border w-full"
      type="tertiary"
      padding="md"
      rounding="md"
      @click="active = true"
    >
      Toggle feature flags
    </Button>
    <Drawer
      v-model:visible="active"
      header="Feature flags"
      position="bottom"
      class="text-base p-4 overflow-auto"
    >
      <template #header>
        <div class="flex-center gap-3 mb-2">
          <p class="text-base-priority text-lg font-bold mb-1">Feature Flags</p>

          <Button
            type="neutral"
            padding="wide-short"
            rounding="md"
            @click="sortFeatureFlags(featureFlags)"
          >
            Sort
          </Button>
          <label for="search" class="text-white ml-3">Search</label>
          <input
            name="search"
            class="pl-3 p-2 text-md text-white bg-dark-800 h-min rounded-md std-border"
            v-model="searchText"
            @update:model-value="debounceSearch()"
          />
          <Button
            type="neutral"
            padding="wide-short"
            rounding="md"
            @click="reset()"
            class="ml-3"
          >
            Reset
          </Button>
        </div>
      </template>
      <div
        class="text-base grid grid-cols-5 gap-2 max-h-[60vh] overflow-scroll"
      >
        <div
          v-for="({ name, key, features }, index) in featureFlags"
          :key="`ff-${index}`"
          class="bg-primary-layer p-2 rounded-lg"
        >
          <div class="flex content-center flex-nowrap">
            <div class="font-bold">{{ name }}</div>
            <ToggleSwitch
              :key="name"
              :model-value="catToggle[key]"
              @update:model-value="setFFCategory(key, index)"
              class="ml-2 mb-2"
            />
          </div>
          <hr class="mb-1" />
          <div
            v-for="({ name: ffName, key: ffKey }, fIndex) in features"
            :key="`ff-${fIndex}-${index}`"
            class="flex justify-between mb-1"
          >
            <div class="w-9/12">
              <div class="truncate">
                {{ ffName }}
              </div>
              <div
                class="text-xs cursor-pointer"
                @click="copyToClipboard(`${key}.${ffKey}`.toLowerCase())"
              >
                {{ `${key}.${ffKey}`.toLowerCase() }}
              </div>
            </div>

            <ToggleSwitch
              :model-value="
                siteStore.featureFlags.get(`${key}.${ffKey}`.toLowerCase())
              "
              @change="
                siteStore.featureFlags.set(
                  `${key}.${ffKey}`.toLowerCase(),
                  !siteStore.featureFlags.get(`${key}.${ffKey}`.toLowerCase()),
                )
              "
            />
          </div>
        </div>
      </div>
    </Drawer>
  </div>
</template>
