<script lang="ts" setup>
import { environmentVariables } from '~/assets/data/config';
import Select from 'primevue/select';
import { globalKeys } from 'assets/data/global-keys';
const { $storageService } = useServices();
const siteStore = useSiteStore();
async function changeEnvironment(r: string) {
  $storageService.setUniversalStorage({
    key: globalKeys.developerConfigKeys.debugEnvironment,
    data: r,
  });
  document.location.href = '/';
}
</script>
<template>
  <div class="card flex justify-center">
    <Select
      :model-value="siteStore.getEnvironment"
      :options="Object.keys(environmentVariables)"
      placeholder="Select Environment"
      class="w-full bg-base"
      @change="(e) => changeEnvironment(e.value)"
    />
  </div>
</template>
